<template>
  <div
    ref="refParent"
    class="b-cities container"
  >
    <div
      class="b-cities__wrap d-flex flex-column relative"
      data-test="cities-coverage-area"
    >
      <d-btn
        v-if="step > 1"
        icon
        color="custom"
        class="b-cities__back"
        @click="step--"
      >
        <img
          loading="lazy"
          src="@/assets/images/svg/arrow-right.svg"
          alt="arrow"
          style="transform: rotate(180deg)"
        >
      </d-btn>
      <h2
        v-if="$route.name !== 'locations' && title"
        class="b-cities__title headline-2"
        :class="title.includes('%FULL_ADDRESS%') ? 'd-flex flex-column' : ''"
      >
        {{ title.replace('%CITY_LOC%', ' ').replace('%FULL_ADDRESS%', '') }}
        <span
          v-if="title.includes('%CITY_LOC%')"
          class="pointer b-cities__title-city"
          @click="showDialogCity"
        >{{ getAddressText.cityOnlyLoc }}</span>
        <span
          v-if="title.includes('%FULL_ADDRESS%')"
          class="pointer secondary-dark--text title d-flex b-cities__title-address"
          @click="showDialogAddress"
        >{{ getAddressText.fullAddress }}</span>
      </h2>
      <VWindow
        v-model="step"
        :disabled="false"
        :touch="{ right: customTouchHandlers, left: () => {} }"
        class="b-cities__window"
      >
        <VWindowItem
          v-if="cities"
          :value="1"
          eager
          class="d-flex flex-column"
        >
          <p
            v-if="$route.name !== 'locations'"
            class="b-cities__subtitle title-2 gray-dark--text"
          >
            {{ subtitle }}
          </p>
          <div
            v-if="regions"
            class="b-cities__type"
          >
            <button
              :class="{ active: !windowRegions }"
              @click="changeTab(false)"
            >
              Города
            </button>
            <button
              :class="{ active: windowRegions }"
              @click="changeTab(true)"
            >
              Регионы
            </button>
          </div>
          <div
            v-if="showSearch"
            class="b-cities__search"
          >
            <img
              loading="lazy"
              src="@/assets/images/svg/search-thin.svg"
              alt="search"
            >
            <input
              type="text"
              :value="searchCity"
              :placeholder="windowRegions ? 'Найти регион' : 'Найти город'"
              autocomplete="honorific-suffix"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              @focus="setRange"
              @input="inputSearch"
              @keyDown="keyDown"
            >
          </div>
          <div class="b-cities__letters">
            <div
              class="d-flex"
              style="margin-bottom: 10px"
            >
              <button
                v-for="letter in letters"
                :key="letter"
                class="b-cities__letters-btn subhead-3"
                :class="{ active: searchCity === letter }"
                @click="setFirstLetter(letter, windowRegions ? 'region' : 'city')"
              >
                {{ letter }}
              </button>
            </div>
          </div>
          <VWindow v-model="windowRegions">
            <VWindowItem
              eager
              :value="false"
              class="d-flex flex-column"
            >
              <div class="b-cities__result">
                <template v-if="loadingCity">
                  <div
                    v-for="item in 20"
                    :key="'load' + item"
                    class="b-cities__result-item title-5 d-flex flex-column"
                    style="height: 47px"
                  >
                    <VSkeleton
                      style="width: 180px; height: 22px; max-width: 70%"
                    />
                  </div>
                </template>
                <template
                  v-for="(item, i) in citiesArr"
                  v-else
                  :key="'city' + i"
                >
                  <component
                    :is="item.in_sitemap ? 'a' : 'button'"
                    :title="`${
                      item.short_name
                        ? item.short_name + ' '
                        : ''
                    }${item.name}`"
                    class="b-cities__result-item title-5"
                    :style="`max-height: ${
                      i < counter
                        ? 'unset; min-height: 47px;'
                        : '0; padding: 0; border: none;'
                    }`"
                    :href="item.in_sitemap ? `/${item.url}/${slug ? slug : ''}` : undefined"
                    @click.prevent="goToStreets(item)"
                  >
                    {{
                      `${
                        item.short_name
                          ? item.short_name + ' '
                          : ""
                      }${item.name}`
                    }}<span
                      v-if="item.parents_tooltip"
                      class="d-flex subhead-6 gray-dark--text"
                    >{{ item.parents_tooltip }}</span>
                  </component>
                </template>
              </div>
              <button
                v-if="counter <= citiesArr.length"
                class="b-cities__show-more primary--text subhead-3"
                @click="addMoreCity('city', counter)"
              >
                Показать ещё
              </button>
            </VWindowItem>
            <VWindowItem
              eager
              :value="true"
              class="d-flex flex-column"
            >
              <div class="b-cities__result">
                <template v-if="loadingCity">
                  <div
                    v-for="item in 20"
                    :key="'load' + item"
                    class="b-cities__result-item title-5 d-flex flex-column"
                    style="height: 47px"
                  >
                    <VSkeleton
                      style="width: 180px; height: 22px; max-width: 70%"
                    />
                  </div>
                </template>
                <template
                  v-for="(item, i) in regionsArr"
                  v-else
                  :key="'city' + i"
                >
                  <a
                    v-if="!partner"
                    :title="`${
                      item.full_name
                        ? item.full_name + ' '
                        : ''
                    }`"
                    class="b-cities__result-item title-5"
                    :style="`max-height: ${
                      i < counter
                        ? 'unset; min-height: 47px;'
                        : '0; padding: 0; border: none;'
                    }`"
                    :href="`/${item.url}/`"
                    @click.prevent="goToRegionsCities(item)"
                  >
                    {{
                      `${item.full_name}`
                    }}
                  </a>
                  <button
                    v-else
                    :style="`max-height: ${
                      i < counter
                        ? 'unset; min-height: 47px;'
                        : '0; padding: 0; border: none;'
                    }`"
                    class="b-cities__result-item title-5"
                    @click="goToRegionsCities(item)"
                  >
                    {{
                      `${item.full_name}`
                    }}
                  </button>
                </template>
              </div>
              <button
                v-if="counter < regionsArr.length"
                class="b-cities__show-more primary--text subhead-3"
                @click="addMoreCity('region', counter)"
              >
                Показать ещё
              </button>
            </VWindowItem>
          </VWindow>
        </VWindowItem>
        <VWindowItem
          v-if="windowRegions"
          :value="2"
          class="d-flex flex-column"
        >
          <div
            v-if="showSearch"
            class="b-cities__search"
          >
            <img
              loading="lazy"
              src="@/assets/images/svg/search-thin.svg"
              alt="search"
            >
            <input
              type="text"
              :value="searchCity2"
              placeholder="Найти город"
              autocomplete="honorific-suffix"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              @focus="setRange"
              @input="inputSearch2"
              @keyDown="keyDown"
            >
          </div>
          <div class="b-cities__letters">
            <div
              class="d-flex"
              style="margin-bottom: 10px"
            >
              <button
                v-for="letter in letters"
                :key="letter"
                class="b-cities__letters-btn subhead-3"
                :class="{ active: searchCity2 === letter }"
                @click="setFirstLetter2(letter, 'city')"
              >
                {{ letter }}
              </button>
            </div>
          </div>
          <div class="b-cities__result">
            <template v-if="loadingCity2">
              <div
                v-for="item in 20"
                :key="'load' + item"
                class="b-cities__result-item title-5 d-flex flex-column"
                style="height: 47px"
              >
                <VSkeleton
                  style="width: 180px; height: 22px; max-width: 70%"
                />
              </div>
            </template>
            <template
              v-for="(item, i) in citiesArr"
              v-else
              :key="'city' + i"
            >
              <component
                :is="item.in_sitemap ? 'a' : 'button'"
                :title="`${
                  item.short_name
                    ? item.short_name + ' '
                    : ''
                }${item.name}`"
                class="b-cities__result-item title-5"
                :style="`max-height: ${
                  i < counter
                    ? 'unset; min-height: 47px;'
                    : '0; padding: 0; border: none;'
                }`"
                :href="item.in_sitemap ? `/${item.url}/${slug ? slug : ''}` : undefined"
                @click.prevent="goToStreets(item)"
              >
                {{
                  `${
                    item.short_name
                      ? item.short_name + ' '
                      : ""
                  }${item.name}`
                }}<span
                  v-if="item.parents_tooltip"
                  class="d-flex subhead-6 gray-dark--text"
                >{{ item.parents_tooltip }}</span>
              </component>
            </template>
          </div>
          <button
            v-if="counter < citiesArr.length"
            class="b-cities__show-more primary--text subhead-3"
            @click="addMoreCity('city', counter)"
          >
            Показать ещё
          </button>
        </VWindowItem>
        <VWindowItem
          :value="!houses && !cities ? 1 : windowRegions ? 3 : 2"
          class="d-flex flex-column"
        >
          <div
            v-if="showSearch"
            class="b-cities__search"
          >
            <img
              loading="lazy"
              src="@/assets/images/svg/search-thin.svg"
              alt="search"
            >
            <input
              type="text"
              :value="searchStreet"
              placeholder="Найти улицу"
              autocomplete="honorific-suffix"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              @focus="setRange"
              @input="inputSearchStreet"
              @keyDown="keyDown"
            >
          </div>
          <div class="b-cities__letters">
            <div
              class="d-flex"
              style="margin-bottom: 10px"
            >
              <button
                v-for="letter in letters"
                :key="letter"
                class="b-cities__letters-btn subhead-3"
                :class="{ active: searchStreet === letter }"
                @click="setFirstLetterStreet(letter, 'street')"
              >
                {{ letter }}
              </button>
            </div>
          </div>
          <div class="b-cities__result">
            <template v-if="loadingStreet">
              <div
                v-for="item in 20"
                :key="'load' + item"
                class="b-cities__result-item title-5 d-flex flex-column"
                style="height: 47px"
              >
                <VSkeleton
                  style="width: 180px; height: 22px; max-width: 70%"
                />
              </div>
            </template>
            <template
              v-for="(item, i) in streetArr"
              v-else
              :key="'city' + i"
            >
              <component
                :is="item.in_sitemap && !partner ? 'a' : 'button'"
                :title="`${
                  item.short_name
                    ? item.short_name + ' '
                    : ''
                }${item.name}`"
                class="b-cities__result-item title-5"
                :style="`max-height: ${
                  i < counterStreet
                    ? 'unset; min-height: 47px;'
                    : '0; padding: 0; border: none;'
                }`"
                :href="item.in_sitemap && !partner ? `/${item.url}/` : undefined"
                @click.prevent="goToHouses(item)"
              >
                {{
                  `${
                    item.short_name
                      ? item.short_name + ' '
                      : ""
                  }${item.name}`
                }}<span
                  v-if="item.parents_tooltip"
                  class="d-flex subhead-6 gray-dark--text"
                >{{ item.parents_tooltip }}</span>
              </component>
            </template>
          </div>
          <button
            v-if="counterStreet <= streetArr.length"
            class="b-cities__show-more primary--text subhead-3"
            @click="addMoreCity('street', counterStreet)"
          >
            Показать ещё
          </button>
        </VWindowItem>
        <VWindowItem
          :value="houses ? 1 : !cities ? 2 : windowRegions ? 4 : 3"
          class="d-flex flex-column"
        >
          <div
            v-if="showSearch"
            class="b-cities__search"
          >
            <img
              loading="lazy"
              src="@/assets/images/svg/search-thin.svg"
              alt="search"
            >
            <input
              type="text"
              :value="searchHouse"
              placeholder="Найти дом"
              autocomplete="honorific-suffix"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              @focus="setRange"
              @input="inputSearchHouse"
              @keyDown="keyDown"
            >
          </div>
          <div class="b-cities__result b-cities__result-houses">
            <template v-if="loadingHouse">
              <div
                v-for="item in 40"
                :key="'load' + item"
                class="b-cities__result-item title-5 d-flex flex-column"
                style="height: 47px"
              >
                <VSkeleton
                  style="width: 180px; height: 22px; max-width: 70%"
                />
              </div>
            </template>
            <template v-else>
              <template
                v-for="(item, i) in housesArr"
                :key="'house' + i"
              >
                <component
                  :is="item.in_sitemap && $route.name === 'city-address-slug' ? 'a' : 'button'"
                  class="b-cities__result-item title-5"
                  :style="`max-height: ${
                    i < counterHouses
                      ? 'unset; min-height: 47px;'
                      : '0; padding: 0; border: none;'
                  }`"
                  :href="item.in_sitemap && $route.name === 'city-address-slug' ? `/${currentCity?.url}/address/${houseGenerateUrl(item)}/` : undefined"
                  @click.prevent="goToHouse(item)"
                >
                  {{ `${item.number ? item.number : ""}` }}
                </component>
              </template>
            </template>
          </div>
          <button
            v-if="!(counterHouses > housesArr?.length)"
            class="b-cities__show-more primary--text subhead-3"
            @click="addHouses"
          >
            Показать ещё
          </button>
        </VWindowItem>
      </VWindow>
    </div>
  </div>
</template>

<script setup lang="ts">
import { VWindow, VWindowItem } from 'vuetify/lib/components/VWindow'
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { debounce } from '~/helpers'
import { useCities } from '~/store/cites/cities.store'
import { useMainStore } from '~/store/main/main.store'
import { scrollToBlock } from '~/composible/scrollToBlock'
import { getAddresLocation } from '~/composible/getAddresLoc'

const props = defineProps({
  cities: Array,
  regions: Array,
  streets: Array,
  houses: Array,
  showSearch: {
    type: Boolean,
    default: true,
  },
  fias: String,
  slug: String,
  title: {
    type: String,
    default: 'Подключаем домашний интернет по всей России',
  },
  provider: {},
  subtitle: {
    type: String,
    default:
      'Выберите свой город, чтобы увидеть доступных провайдеров и тарифы',
  },
})

const ctx = useNuxtApp()
const $router = useRouter()
const $route = useRoute()
const tariffsStore = useTariffs()
const cityStore = useCities()
const mainStore = useMainStore()
const refParent = ref()
const step = ref(1)
const searchCity = ref('')
const searchCity2 = ref('')
const searchStreet = ref('')
const searchHouse = ref('')
const windowRegions = ref(false)
const loadingCity = ref(false)
const loadingCity2 = ref(false)
const loadingStreet = ref(false)
const loadingHouse = ref(false)
const loadingMore = ref(false)
const backCheck = ref(false)
const loadingValidate = ref()
const selectedRegion = ref()
const selectedCity = ref()
const selectedStreet = ref()
const getCommonHeaders = computed(() => mainStore.getCommonHeaders)
const currentCity = computed(() => cityStore.getCity)
const getAddressText = computed(() => cityStore.getAddressText)
const partner = computed(() => mainStore.getDomainConfig)
const counter = ref(20)
const counterStreet = ref(20)
const counterHouses = ref(40)

const newCities = ref<any[]>([])
const newRegions = ref<any[]>([])
const newStreets = ref<any[]>([])
const newHouses = ref<any[]>([])
const searchDebounce = debounce((level) => {
  if ((level === 'street' ? searchStreet.value : searchCity.value) || (level === 'city' && windowRegions.value)) {
    if (level !== 'street') counter.value = 20
    else counterStreet.value = 20
    fetchCity(level, 0)
  }
}, 350)
const searchHouseDebounce = debounce(async () => {
  counterHouses.value = 40
  const list = await fetchHouses({ offset: 0, limit: 40, query: searchHouse.value })
  newHouses.value = [...list]
  loadingHouse.value = false
}, 350)
const housesArr = computed(() => newHouses.value.length ? newHouses.value : props.houses || [])
const citiesArr = computed(() => newCities.value.length ? newCities.value : props.cities || [])
const streetArr = computed(() => (newStreets.value.length ? newStreets.value : props.streets || []).map((el) => {
  const index = el.url.lastIndexOf('/')
  return {
    ...el,
    url: el.url.includes('/streets/') ? el.url : el.url.slice(0, index) + '/streets' + el.url.slice(index),
  }
}))
const regionsArr = computed(() =>
  (newRegions.value.length ? newRegions.value : props.regions || []).filter(
    el =>
      el.fias_id !== '0c5b2444-70a0-4932-980c-b4dc0d3f02b5'
      && el.fias_id !== 'c2deb16a-0330-4f05-821f-1d09c93331e6',
  ),
)

const customTouchHandlers = () => {
  if (step.value > 1) {
    step.value -= 1
  }
}

const showDialogCity = () => {
  ctx.$event('cityDialog', { redirect: true, label: 'offer' })
}
const showDialogAddress = () => {
  ctx.$event('addressDialog', { redirect: true, label: 'block-cities-address-page' })
}
const letters = ref([
  'А',
  'Б',
  'В',
  'Г',
  'Д',
  'Е',
  'Ж',
  'З',
  'И',
  'Й',
  'К',
  'Л',
  'М',
  'Н',
  'О',
  'П',
  'Р',
  'С',
  'Т',
  'У',
  'Ф',
  'Х',
  'Ц',
  'Ч',
  'Ш',
  'Щ',
  'Ы',
  'Э',
  'Ю',
  'Я',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
])

const setFirstLetter = (letter: string, level) => {
  if (searchCity.value === letter) searchCity.value = ''
  else searchCity.value = letter
  counter.value = 20
  fetchCity(level, 0)
}
const setFirstLetter2 = (letter: string, level) => {
  if (searchCity2.value === letter) searchCity2.value = ''
  else searchCity2.value = letter
  counter.value = 20
  fetchCity(level, 0)
}
const setFirstLetterStreet = (letter: string, level) => {
  if (searchStreet.value === letter) searchStreet.value = ''
  else searchStreet.value = letter
  counterStreet.value = 20
  fetchCity(level, 0)
}

const setRange = (e) => {
  setTimeout(
    () => e.target.setSelectionRange(e?.target?.value?.length, e?.target?.value?.length),
    1,
  )
}
const inputSearch = (event: any) => {
  event.target.composing = false
  searchCity.value = event.target.value
  if (!event.target.value) {
    loadingCity.value = false
    newCities.value = []
    newRegions.value = []
  }
  else {
    loadingCity.value = true
    loadingValidate.value = Math.random()
    searchDebounce(windowRegions.value ? 'region' : 'city')
  }
}
const inputSearch2 = (event: any) => {
  event.target.composing = false
  searchCity2.value = event.target.value
  loadingCity2.value = true
  loadingValidate.value = Math.random()
  searchDebounce('city')
}
const inputSearchStreet = (event: any) => {
  event.target.composing = false
  searchStreet.value = event.target.value
  if (!event.target.value) {
    loadingStreet.value = false
    newStreets.value = []
  }
  else {
    loadingStreet.value = true
    loadingValidate.value = Math.random()
    searchDebounce('street')
  }
}
const inputSearchHouse = (event: any) => {
  event.target.composing = false
  searchHouse.value = event.target.value
  if (!event.target.value) {
    loadingHouse.value = false
    newHouses.value = []
  }
  else {
    loadingHouse.value = true
    loadingValidate.value = Math.random()
    searchHouseDebounce()
  }
}

const keyDown = (e) => {
  if (e.keyCode === 13) e.preventDefault()
}
const getCities = async (level, offset) => {
  return await $fetch(`https://${mainStore.domain}/api/locations/address-objects/children/`, {
    method: 'GET',
    headers: {
      ...getCommonHeaders.value,
    },
    params: {
      fias_id: level === 'street'
        ? (selectedCity.value?.fias_id || currentCity.value?.fias_id)
        : level === 'city' ? (windowRegions.value ? selectedRegion.value?.fias_id : props.fias) : undefined,
      query: level === 'street' ? searchStreet.value : level === 'city' && windowRegions.value ? searchCity2.value : searchCity.value,
      city_type: 'all',
      sort: (level === 'street' && searchStreet.value) ? 'MATCH' : 'POPULATION',
      level,
      limit: 100,
      with_available_smart_filters: false,
      with_provider_slugs: false,
      offset,
      optimized: true,
      providers: props.provider?.id ? [props.provider.id] : undefined,
    },
  })
}
const fetchCity = async (level, offset) => {
  const validate = Math.random()
  loadingValidate.value = validate
  const res = await getCities(level, offset)
  if (loadingValidate.value === validate) {
    loadingStreet.value = false
    loadingCity.value = false
    loadingCity2.value = false
    if (level === 'city') newCities.value = res
    else if (level === 'region') newRegions.value = res
    else newStreets.value = res
  }
}
const addMoreCity = async (level, offset) => {
  if (level === 'city' && citiesArr.value?.length >= offset + 20) {
    counter.value += 20
    return
  }
  else if (level === 'region' && regionsArr.value?.length >= offset + 20) {
    counter.value += 20
    return
  }
  else if (level === 'streets' && streetArr.value?.length >= offset + 20) {
    counterStreet.value += 20
    return
  }
  loadingMore.value = true
  const validate = Math.random()
  loadingValidate.value = validate
  const res = await getCities(level, offset)
  if (loadingValidate.value === validate) {
    loadingMore.value = false
    if (level === 'city') {
      counter.value += 20
      newCities.value = [...(citiesArr.value || []), ...(res || [])]
    }
    else if (level === 'region') {
      counter.value += 20
      newRegions.value = [...(regionsArr.value || []), ...(res || [])]
    }
    else {
      counterStreet.value += 20
      newStreets.value = [...(streetArr.value || []), ...(res || [])]
    }
  }
}

const addHouses = async () => {
  const list = await fetchHouses({ offset: counterHouses.value, limit: 40, query: searchHouse.value })
  counterHouses.value += 40
  newHouses.value = [...housesArr.value, ...list]
}
const fetchHouses = async (input?: any) => {
  return await $fetch(`https://${mainStore.domain}/api/locations/streets/${selectedStreet.value?.fias_id}/houses/`, {
    method: 'GET',
    headers: {
      ...getCommonHeaders.value,
    },
    params: {
      limit: 100,
      ...input,
    },
  })
}
const changeTab = (val: boolean) => {
  counter.value = 20
  windowRegions.value = val
  searchCity.value = ''
  searchCity2.value = ''
  newCities.value = []
  newRegions.value = []
}
const redirectTo = async (item) => {
  let result
  try {
    result = await $fetch(`https://${mainStore.domain}/api/locations/address-objects/${item.fias_id}/ancestors/`, {
      method: 'GET',
      headers: {
        ...getCommonHeaders.value,
      },
      params: {
        include_self: true,
        morph: true,
      },
    })
  }
  catch (e) {}

  const { res } = cityStore.getAddressObjectsFromResponse(result)

  cityStore.$patch({
    addressAncestors: res,
  })
  cityStore.house = {}
  tariffsStore.passThroughProviderId = null

  const coockieAddress = useCookie('currentAddress')
  coockieAddress.value = ''
  nextTick(() => {
    $router.push({ path: `/${item.url}/${props.slug ? props.slug : ''}` })
  })
}
const redirectToRegion = async (item: any) => {
  let res
  try {
    res = await $fetch(`https://${mainStore.domain}/api/locations/address-objects/${item.fias_id}/ancestors/`, {
      method: 'GET',
      headers: {
        ...getCommonHeaders.value,
      },
      params: {
        include_self: true,
        morph: true,
      },
    })
  }
  catch (e) {}

  cityStore.$patch({
    currentRegion: res?.address_objects?.[0],
  })

  nextTick(() => {
    $router.push({ path: `/${item.url}/${props.slug ? props.slug : ''}` })
  })
}
const goToStreets = async (item) => {
  if ($route.name === 'locations') {
    redirectTo(item)
    return
  }
  else if (item.level === 'region') {
    redirectToRegion(item)
    return
  }
  searchStreet.value = ''
  loadingStreet.value = true
  goForward()
  selectedCity.value = item
  fetchCity('street', 0)
  scrollToBlock(refParent.value, 750, 44)
}
const goToRegionsCities = async (item) => {
  if ($route.name === 'locations') {
    redirectToRegion(item)
    return
  }
  searchCity2.value = ''
  loadingStreet.value = true
  goForward()
  selectedRegion.value = item
  fetchCity('city', 0)
  scrollToBlock(refParent.value, 750, 44)
}
const goToHouses = async (item) => {
  if ($route.name === 'city-streets') {
    redirectTo(item)
    return
  }
  selectedStreet.value = item
  loadingHouse.value = true
  counterHouses.value = 40
  goForward()
  const list = await fetchHouses({ limit: 40 })
  newHouses.value = list
  loadingHouse.value = false
  scrollToBlock(refParent.value, 750, 44)
}
const houseGenerateUrl = (item) => {
  return item?.url?.replace(currentCity.value?.url, '').replace(/^\//, '').replace(/(.*)\/([^/]*)/, '$1-dom-$2')
}
const goToHouse = async (item) => {
  if (!partner.value) {
    mainStore.$patch({
      showLoader: true,
    })
  }
  let res

  const { getAddresLoc } = getAddresLocation()

  const promiseLoc = async () => {
    await getAddresLoc({
      url: item.autogenerated_url,
    })
  }
  const promiseRes = async () => {
    if (selectedStreet.value) res = await $fetch(`https://${mainStore.domain}/api/locations/address-objects/${selectedStreet.value.fias_id}/ancestors/`, {
      method: 'GET',
      headers: {
        ...getCommonHeaders.value,
      },
      params: {
        include_self: true,
        morph: true,
      },
    })
  }

  await Promise.all([promiseLoc(), promiseRes()])

  if (selectedStreet.value) {
    let city = res?.address_objects.find(el => el.level === 'city')
    if (!city) {
      city = res?.address_objects.find(el => el.level === 'street')
    }
    cityStore.$patch({
      addressAncestors: res,
    })
  }
  const coockieAddress = useCookie('currentAddress')
  coockieAddress.value = item.autogenerated_url

  if (!partner.value) {
    if (item.in_sitemap && $route.name === 'city-address-slug') $router.push({
      path: `/${currentCity.value?.url}/address/${houseGenerateUrl(item)}/`,
    })
    else $router.push({
      path: `/personal/address/${item.autogenerated_url}/`,
    })
  }
  else {
    $router.push({
      path: `/${currentCity.value?.url}/`,
    })
  }
}

const goForward = async () => {
  backCheck.value = false
  if (!$router?.options?.history?.state?.back || !/.*#checkAddress$/.test($router?.options?.history?.state?.back)) {
    await $router.push({ path: $route.path, query: $route.query, hash: '#checkAddress' })
    $router.push({ path: $route.path, query: $route.query })
  }
  backCheck.value = true
  step.value++
}
onBeforeRouteUpdate(async (to, from, next) => {
  const stepvalue = step.value
  if (backCheck.value && step.value > 1 && to.hash === '#checkAddress') {
    next(false)
    step.value = stepvalue - 1
  }
  else if (backCheck.value && step.value === 1 && to.hash === '#checkAddress') {
    $router.back(-3)
  }
  else {
    next()
  }
})
onMounted(() => {
  console.log('mounted')
})
</script>

<style scoped lang="scss">
.b-cities {
  @media (max-width: getBreakpoint(tablet)) {
    padding: 0;
  }

  &__wrap {
    width: 100%;
    overflow: hidden;
    background: color(white);
    border-radius: 16px;
    box-shadow: 0px 0px 10px rgba(2, 4, 30, 0.1);
    padding: 40px 24px;
    @media (max-width: getBreakpoint(tablet)) {
      padding: 40px 16px;
      border-radius: 0;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      padding: 40px 8px;
    }
  }
  &__back {
    position: absolute;
    right: 24px;
    top: 40px;
    @media (max-width: getBreakpoint(tablet)) {
      top: 16px;
      right: 8px;
    }
  }
  &__loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(color(white), 0.7);
  }

  &__title {
    margin-bottom: 16px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 8px;
    }
    &-city {
      position: relative;
      text-decoration: underline;
      text-decoration-color: color(primary);
    }
    &-address {
      margin-right: auto;
    }
  }

  &__search {
    position: relative;
    img {
      display: flex;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      left: 18px;
    }
    input {
      width: 100%;
      background: color(gray-p);
      border: 1px solid color(gray-darker);
      border-radius: 28px;
      transition: border 0.24s;
      padding: 16px 48px;
      &:focus {
        border: 1px solid color(secondary-dark);
      }
      @media (max-width: getBreakpoint(tablet)) {
        height: 40px;
      }
    }
  }

  &__subtitle {
    margin-bottom: 16px;
  }
  &__type {
    border: 1px solid color(gray-light-3);
    padding: 2px;
    margin-right: auto;
    margin-bottom: 24px;
    border-radius: 56px;
    @media (max-width: getBreakpoint(tablet)) {
      width: 400px;
      max-width: 100%;
    }
    button {
      border: 1px solid transparent;
      padding: 8px 20px;
      border-radius: 56px;
      @media (max-width: getBreakpoint(tablet)) {
        width: 50%;
        text-align: center;
        height: 32px;
        padding: 0;
      }

      &.active {
        border: 1px solid color(primary);
        color: color(primary);
      }
    }
  }
  &__search {
    margin-bottom: 24px;

    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 12px;
    }
  }

  &__letters {
    margin-bottom: 4px;
    overflow-x: auto !important;
    overflow-y: hidden !important;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background: color(white);
      border-radius: 4px;
      transition: background .24s;
    }
    &::-webkit-scrollbar-thumb {
      background-color: color(white);
      transition: background-color .24s;
    }
    &:hover {
      &::-webkit-scrollbar-track {
        background: color(gray-light);
      }
      &::-webkit-scrollbar-thumb {
        background-color: color(gray-2);
      }
    }
    &-btn {
      padding: 11px 19px;
      border-radius: 28px;
      background-color: color(gray-p);
      margin-right: 8px;
      transition: background-color 0.24s;
      box-sizing: border-box;
      border: 1px solid color(gray-light-3);

      &.active {
        border: 1px solid color(primary);
      }
      &:hover {
        background-color: color(gray-light-3);
      }
    }
  }

  &__result {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @media (max-width: getBreakpoint(tablet)) {
      grid-template-columns: repeat(2, 1fr);
    }
    &-houses {
      grid-template-columns: repeat(8, 1fr);
      @media (max-width: getBreakpoint(tablet)) {
        grid-template-columns: repeat(4, 1fr);
      }
    }
    &-item {
      padding: 12px 12px 11px 0;
      border-bottom: 1px solid color(gray-light-3);
      overflow: hidden;
      color: color(black);
      transition: color 0.24s;
      text-decoration: none;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      &:hover {
        color: color(primary);
      }
      span {
        margin-bottom: -8px;
      }
    }
  }

  &__show-more {
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
